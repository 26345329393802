<template>

    <div class="cart" :class="{'extended': extended}">
        <div v-if="isEmpty" class="cart-empty uk-padding uk-flex uk-flex-column">
            <h3 class="cart-empty-text">Your cart is empty. Now might be a good time to add something!</h3>
            <form-button type="primary">SHOP NOW</form-button>
        </div>


        <div v-if=" ! isEmpty" class="cart-main">
            <div v-if="summaryPosition == 'top' || summaryPosition == 'both'"
                    class="cart-header-summery uk-panel uk-background-muted uk-padding-small  uk-text-center">
                <div class="uk-flex uk-flex-between">
                    <div class="header-item-count ">
                        <span class="uk-margin-small-right">Total {{itemCount}} items:</span>
                    </div>
                    <div class="header-prices uk-text-right" >
                        <div class="fixed  ">
                            <div class="cart-header-final-price">
                                <inline-block>2 525 ₣</inline-block>
                                <inline-block class="cart-header-spacer" style="width: 34px"></inline-block>
                            </div>
                        </div>
                        <div class="recurring">
                            <div class="cart-header-final-price">
                                <inline-block>+ 525 ₣</inline-block>
                                <inline-block class="uk-text-small" style="width: 34px;">/ MO</inline-block>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="cart-content uk-padding-small uk-flex ">

                <div class="uk-flex-uk-flex-column uk-overflow-auto uk-width-expand uk-padding uk-padding-remove-horizontal ">
                    <!-- regular product demo -->
                    <div class="entry">
                        <div class="entry-inner uk-flex uk-flex-stretch">
                            <div class="entry-image uk-width-small uk-background-cover uk-margin-right"
                                 :style="`background-image: url('https://via.placeholder.com/150')`">

                            </div>
                            <div class="entry-details uk-width-3-6 uk-margin-right">
                                <div class="details-wrap">
                                    <div class="entry-title">Some Product</div>
                                    <div class="entry-detail uk-margin-small-bottom">
                                        <span class="entry-qty-label">Quantity: </span><span class="entry-qty-number">2</span>
                                        <br>
                                        <span>Short product description</span>
                                    </div>
                                    <form-button
                                            class="line-edit-button"
                                            size="small"
                                            icon="pencil"
                                            type="secondary">Edit / Remove</form-button>
                                </div>
                            </div>
                            <div class="entry-price uk-flex uk-flex-column" v-if="showPricePerEntry">
                                <div class="entry-final-price" >750 ₣</div>
                                <div class="entry-old-price
                            uk-text-small" style="text-decoration: line-through">900 ₣</div>
                            </div>
                        </div>
                        <hr class="uk-hr">
                    </div>

                    <!-- regular sub demo -->
                    <div class="entry">
                        <div class="entry-inner entry-builder uk-flex uk-flex-stretch">
                            <div class="entry-image uk-width-small uk-background-cover uk-margin-right"
                                 :style="`background-image: url('images/nice.jpg')`">

                            </div>
                            <div class="entry-details uk-width-expand uk-margin-right">
                                <div class="details-wrap">
                                    <div class="entry-title">20Go Plan + Device</div>
                                    <div class="entry-detail uk-margin-small-bottom">
                                        <span class="commitment-qty-label">commitment: </span><span class="entry-qty-number">12 MO</span>
                                        <br>
                                        <span>20Go 4G data,<br>iPhone 12 pro Max<br> 256Go, Matte blue<br>Phone Number: 89 213 828</span>
                                    </div>
                                    <form-button
                                            class="line-edit-button"
                                            size="small"
                                            icon="pencil"
                                            type="secondary">Edit / Remove</form-button>
                                </div>

                            </div>
                            <div class="entry-price uk-flex uk-flex-column " v-if="showPricePerEntry">
                                <div class="entry-final-price" >7 650 ₣</div>
                                <div class="entry-old-price uk-text-small" style="text-decoration: line-through">9 900 ₣</div>
                                <div class="cart-price-separator"></div>
                                <div class=" uk-text-small uk-text-right" >+1 550 ₣ / MO</div>
                                <div class="uk-text-small uk-text-right">
                                    <span>12 months</span> <span uk-icon="icon: warning;ratio: 0.8" class="uk-text-danger" uk-tooltip="1 550 ₣/MO for the first 12 months, 1 750 ₣/MO afterwards"></span>
                                </div>
                                <div class="cart-price-separator"></div>
                                <div class=" uk-text-small uk-text-right" >1 750 ₣ / MO</div>
                                <div class="uk-text-small uk-text-right">
                                    <span>afterwards</span>
                                </div>

                            </div>

                        </div>
                        <hr class="uk-hr" v-if="extended">
                    </div>

                    <!-- sub with no phone -->
                    <div class="entry" v-if="extended">
                        <div class="entry-inner entry-builder uk-flex uk-flex-stretch">
                            <div class="entry-image uk-width-small uk-background-cover uk-margin-right"
                                 :style="`background-image: url('images/nice.jpg')`">

                            </div>
                            <div class="entry-details uk-width-expand uk-margin-right uk-flex">
                                <div class="details-wrap">
                                    <div class="entry-title">10Go Plan</div>
                                    <div class="entry-detail uk-margin-small-bottom">
                                        <span class="commitment-qty-label">commitment: </span><span class="entry-qty-number">12 MO</span>
                                        <br>
                                        <span>20Go 4G data,<br><strong>NO DEVICE</strong><br>Phone Number: 89 213 828</span>
                                    </div>
                                    <form-button
                                            class="line-edit-button"
                                            size="small"
                                            icon="pencil"
                                            type="secondary">Edit / Remove</form-button>
                                </div>
                                <div class="details-cta uk-height-1-1 uk-width-expand uk-border-radius uk-background-secondary uk-padding uk-text-center uk-text-inverse-color uk-margin-large-left uk-margin-large-right">
                                    <h3 class="uk-text-inverse-color uk-margin-remove-bottom"><strong>ATTENTION!</strong></h3>
                                    <div class="uk-margin-bottom"><span>Add a device for only 99 F / MO!</span></div>
                                    <form-button type="primary"
                                                 @click="$router.push({'name': 'builder-demo'})"
                                                 icon="plus"
                                                 :full-width="true">GET YOUR DEVICE</form-button>
                                </div>

                            </div>
                            <div class="entry-price uk-flex uk-flex-column " v-if="showPricePerEntry">
                                <div class="entry-final-price" >7 650 ₣</div>
                                <div class="entry-old-price uk-text-small" style="text-decoration: line-through">9 900 ₣</div>
                                <div class="cart-price-separator"></div>
                                <div class=" uk-text-small uk-text-right" >+1 550 ₣ / MO</div>
                                <div class="uk-text-small uk-text-right">
                                    <span>12 months</span> <span uk-icon="icon: warning;ratio: 0.8" class="uk-text-danger" uk-tooltip="1 550 ₣/MO for the first 12 months, 1 750 ₣/MO afterwards"></span>
                                </div>
                                <div class="cart-price-separator"></div>
                                <div class=" uk-text-small uk-text-right" >1 750 ₣ / MO</div>
                                <div class="uk-text-small uk-text-right">
                                    <span>afterwards</span>
                                </div>

                            </div>
                        </div>

                        <hr class="uk-hr" v-if="extended">
                    </div>

                    <div class="entry entry--last" v-if="extended">
                        <div class="entry-inner uk-flex uk-flex-stretch">
                            <div class="entry-image uk-width-small uk-background-cover uk-margin-right"
                                 :style="`background-image: url('images/products/iphone12_1.jpg')`">

                            </div>
                            <div class="entry-details uk-width-3-6 uk-margin-right uk-flex">
                                <div class="details-wrap">
                                    <div class="entry-title">iPhone 12 PRO</div>
                                    <div class="entry-detail uk-margin-small-bottom">
                                        <span class="entry-qty-label">Quantity: </span><span class="entry-qty-number">1</span>
                                        <br>
                                        <span>Short product description</span>
                                    </div>
                                    <form-button
                                            class="line-edit-button"
                                            size="small"
                                            icon="pencil"
                                            type="secondary">Edit / Remove</form-button>
                                </div>

                                <div class="details-cta uk-height-1-1 uk-width-expand uk-border-radius uk-background-secondary uk-padding uk-text-center uk-text-inverse-color uk-margin-large-left uk-margin-large-right">
                                    <h3 class="uk-text-inverse-color uk-margin-remove-bottom"><strong>ATTENTION!</strong></h3>
                                    <div class="uk-margin-bottom"><span>Get this with a monthly subscription and <strong>SAVE 1 500 F!</strong></span></div>
                                    <form-button
                                            @click="$router.push({'name': 'builder-demo'})"
                                            type="primary"
                                            icon="plus"
                                            :full-width="true">ADD A SUBSCRIPTION</form-button>
                                </div>

                            </div>
                            <div class="entry-price uk-flex uk-flex-column" v-if="showPricePerEntry">
                                <div class="entry-final-price" >750 ₣</div>
                                <div class="entry-old-price
                            uk-text-small" style="text-decoration: line-through">900 ₣</div>
                            </div>
                        </div>
                        <hr class="uk-hr">
                    </div>
                </div>

                <div id="test" class="type-summary-wrapper  uk-margin-large-left uk-width-1-3" v-if="showPriceSummeryByType">
                    <!-- this becomes sticky from script-->
                    <div class="type-price-summary uk-background-secondary uk-border-radius-small"
                         ref="priceSummarySticky"
                         v-if="showPriceSummeryByType">
                        <h3 class="uk-text-inverse-color uk-margin-auto uk-display-block uk-text-center uk-margin-top uk-padding uk-padding-remove-vertical" v-if="showPriceSummeryByType">Summary</h3>

                        <div class="price-type-block uk-background-inverse uk-margin-bottom  uk-padding-small uk-margin-small">
                            <div class="type-summary uk-flex ">

                                <div class="desc-col uk-width-2-3 uk-margin-right"
                                     :class="{'active' : expandedImmediatePrice}">
                                    <div class="uk-flex uk-flex-middle"

                                         @click="toggleMonthlyPrice()">
                                        <span class="desc-icon" uk-icon="chevron-right"></span>
                                        <span class="type-summary-expand-text">Today</span>
                                    </div>
                                </div>
                                <div class="price-col uk-width-1-3 uk-text-right">
                                    12 700 F
                                </div>

                            </div>

                            <div class="type-details uk-animation-slide-left-small" v-show="expandedImmediatePrice">
                                <hr class="uk-margin-top">
                                <div class="type-detail uk-flex">
                                    <div class="desc-col uk-width-2-3 uk-margin-right">
                                        Some device
                                    </div>
                                    <div class="price-col uk-width-1-3 uk-text-right">
                                        9 700 F
                                    </div>
                                </div>

                                <div class="type-detail uk-flex">
                                    <div class="desc-col uk-width-2-3 uk-margin-right">
                                        Another device
                                    </div>
                                    <div class="price-col uk-width-1-3 uk-text-right">
                                        5 700 F
                                    </div>
                                </div>
                                <div class="type-detail uk-flex">
                                    <div class="desc-col uk-width-2-3 uk-margin-right">
                                        Sim Card, 3pcs
                                    </div>
                                    <div class="price-col uk-width-1-3 uk-text-right">
                                        0 F
                                    </div>
                                </div>


                            </div>

                        </div>

                        <div class="price-type-block uk-background-inverse  uk-padding-small uk-margin-small">
                            <div class="type-summary uk-flex ">

                                <div class="desc-col uk-width-2-3 uk-margin-right"
                                     :class="{'active' : expandedMonthlyPrice}">
                                    <div class="uk-flex uk-flex-middle"

                                         @click="toggleImmediatePrice()">
                                        <span class="desc-icon" uk-icon="chevron-right"></span>
                                        <span class="type-summary-expand-text">Per month</span>
                                    </div>
                                </div>
                                <div class="price-col uk-width-1-3 uk-text-right">
                                    1 075 F
                                </div>

                            </div>

                            <div class="type-details uk-animation-slide-left-small" v-show="expandedMonthlyPrice">
                                <hr class="uk-margin-top">
                                <div class="type-detail uk-flex uk-margin-small-bottom">
                                    <div class="desc-col uk-width-2-3 uk-margin-right">
                                        10Go plan w/24 MO commitment
                                    </div>
                                    <div class="price-col uk-width-1-3 uk-text-right">
                                        800 F
                                    </div>
                                </div>

                                <div class="type-detail uk-flex uk-margin-small-bottom">
                                    <div class="desc-col uk-width-2-3 uk-margin-right">
                                        Device X facilitated payment
                                    </div>
                                    <div class="price-col uk-width-1-3 uk-text-right">
                                        200 F
                                    </div>
                                </div>
                                <div class="type-detail uk-flex uk-margin-small-bottom">
                                    <div class="desc-col uk-width-2-3 uk-margin-right">
                                        Something else
                                    </div>
                                    <div class="price-col uk-width-1-3 uk-text-right">
                                        75 F
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>

                </div>

            </div>



            <div v-if="summaryPosition == 'bottom' || summaryPosition == 'both'"
                 class="cart-header-summery uk-panel uk-background-muted uk-padding-small  uk-text-center">
                <div class="uk-flex uk-flex-between">
                    <div class="header-item-count ">
                        <span class="uk-margin-small-right">Total {{itemCount}} items:</span>
                    </div>
                    <div class="header-prices uk-text-right" >
                        <div class="fixed  ">
                            <div class="cart-header-final-price">
                                <inline-block>2 525 ₣</inline-block>
                                <inline-block class="cart-header-spacer" style="width: 34px"></inline-block>
                            </div>
                        </div>
                        <div class="recurring">
                            <div class="cart-header-final-price">
                                <inline-block>+ 525 ₣</inline-block>
                                <inline-block class="uk-text-small" style="width: 34px;">/ MO</inline-block>
                            </div>
                        </div>
                    </div>
                </div>



            </div>


        </div>


    </div>

</template>

<script>
    import {computed} from 'vue';
    import { useStore } from 'vuex'
    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
    import ProductUpsaleCarousel from "@/client/components/ecommerce/products/ProductUpsaleCarousel.vue";

    export default {
        setup (props) {
            let {asyncOps, asyncOpsReady} = asyncOperations(props, useStore());

            return {asyncOps, asyncOpsReady}
        },
        props: {
            summaryPosition: {
                type: String,
                default: 'top'
            },
            extended: {
                type: Boolean,
                default: false
            },
            /**
             * Should the cart show pricing for each of its entries
             */
            showPricePerEntry : {
                type: Boolean,
                default: true
            },
            /**
             * Should the cart show price summery that is grouped based on type - recurring or one time
             */
            showPriceSummeryByType: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ProductUpsaleCarousel,
        },

        data: function () {
            return {
                expandedImmediatePrice: false,
                expandedMonthlyPrice: false,
                productIds :     computed ( () => this.$store.getters['cart/productIds']),
                cartEntriesRaw : computed( () => this.$store.getters['cart/entries']),
                products: [],
                asyncData : {
                    products: {
                        target: 'product',
                        data: computed ( () => {return {'ids' : this.productIds};}),
                        options : {
                            method: 'get'
                        },
                        shouldFetch: () => {
                            return Boolean(this.productIds.length > 0)
                        }
                    }
                }
            };
        },
        computed: {
            finalCartEntries () {
                let raw = this.cartEntriesRaw;
                let final = [];

                raw.forEach((entry) => {
                    let product = this.getLocalProductById(entry.id),
                        priceBeforeDiscount = product.price * entry.qty,
                        price = priceBeforeDiscount,
                        discount = entry.discount,
                        discountType = entry.discountType,
                        images = product.images || [],
                        image  = images[0] || {},
                        imageUrl = image.url || '',
                        finalDiscount;

                    if (discount > 0 && discountType == 'flat') {
                        price = priceBeforeDiscount - discount;
                    }

                    if (discount > 0 && discountType == 'percent') {
                        price = Math.ceil(priceBeforeDiscount * (100 - discount) / 100);
                        console.log(entry, product, price);
                    }

                    finalDiscount = priceBeforeDiscount - price;

                    final.push( {
                        product,
                        price,
                        priceBeforeDiscount,
                        hasDiscount : finalDiscount > 0,
                        qty:entry.qty,
                        imageSrc : imageUrl
                    })
                });

                return final;
            },
            cartTotalBeforeDiscount () {
                let total = 0;

                this.finalCartEntries.forEach((entry) => {
                    total = total + entry.priceBeforeDiscount;
                });

                return total;
            },
            cartTotal () {
                let total = 0;

                this.finalCartEntries.forEach((entry) => {
                    total = total + entry.price;

                });

                return total;
            },
            isEmpty () {
               // return this.$store.getters['cart/itemCount'] < 1;
                return false
            },
            itemCount () {
                if (this.extended) {
                    return 5;
                } else {
                    return 3;
                }
            }
        },
        methods: {
            refreshPriceSummarySticky() {

                if ( ! this.$.isMounted ){
                    return;
                }

                let stickyOptions = {
                    bottom: '.entry--last',
                    top: 150,
                    offset: 175
                };


                this.$s.ui.adapter.sticky(this.$refs.priceSummarySticky, stickyOptions);
            },
            getLocalProductById (id) {
                let result = {};
                this.products.forEach((product) => {
                    if (product.id === id) {
                        result = product;
                        return false;
                    }
                });

                return result;
            },
            toggleMonthlyPrice() {
                this.expandedImmediatePrice = ! this.expandedImmediatePrice
                this.refreshPriceSummarySticky();
            },
            toggleImmediatePrice() {
                this.expandedMonthlyPrice = ! this.expandedMonthlyPrice;
                this.refreshPriceSummarySticky();
            }

        },
        watch : {

        },

        created () {

            },
        mounted () {
            this.refreshPriceSummarySticky();
        }
    }
</script>

<style scoped lang="scss">


    .cart-content {
        max-height: calc(100vh - 200px);
        overflow-x: hidden!important;

        .extended & {
            max-height: none;
        }
    }

    .cart-empty {
        color: var(--global-primary-background);
        text-align: center;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;

        > * {
            color: var(--global-primary-background);
            text-align: center;
        }
    }

    .cart-title {
        position: relative;
        padding-top: calc(var(--global-small-gutter)*0.2);
        padding-right: calc(var(--global-small-gutter)*0.8);
        padding-bottom:calc(var(--global-small-gutter)*0.6);
        padding-left: calc(var(--global-small-gutter)*0.8);
    }

    .cart-title-text {
        position: relative;
        top: 3px;
        font-size:var(--text-large-font-size);
    }

    .uk-offcanvas .cart-proceed-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        .uk-button {
            &:hover {
                background-color: var(--global-background)!important;
                color: var(--global-primary-background)!important;
            }
        }

    }

    .cart-header-final-price {
        color: var(--global-primary-background)!important;
        font-size: calc(var(--global-font-size)*1.1);
        font-weight: 500;
    }

    .cart-header-summery {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .cart-header-final-price{
        margin-right: 2px;
    }

    .cart-main {
        max-height: calc(100% - 130px )
    }

    .entry-price {
        width: 95px;
        flex: 0 0 95px;

        .entry-final-price {
            white-space: nowrap;
            border-radius: var(--border-radius-small);
        }

        .cart-price-separator{
            margin: 5px 0;
            height: 1px;
            width: 100%;
            background: var(--global-muted-color);
        }

        .entry-final-price {
            text-align: center;
            width: 100%;
            padding: calc(var(--global-small-gutter)/4) calc(var(--global-small-gutter)/2);
            background: var(--global-primary-background);
            color: var(--global-background);
            font-weight: 600;
        }

        .entry-old-price {
            text-align: center;
            width: 100%;

        }
    }

    .entry-image {
        border: 2px solid var(--global-muted-background);
        padding: 2px;
        width: 100px;
        flex: 0 0 100px;
    }

    .entry-title{
        font-weight: 600;
    }

    .entry-detail {
        font-size: calc(var(--global-font-size)*0.8);
    }

    .details-wrap {
        .extended & {
            min-width: 170px;
        }
    }
    .details-cta {
        max-width: 400px;
    }


    .type-price-summary {
        height: auto;
        align-self: flex-start;
        padding-top: calc(var(--global-margin) * 1);
        padding-left: calc(var(--global-margin) * 0.75);
        padding-right: calc(var(--global-margin) * 0.75);
        padding-bottom: calc(var(--global-margin) * 1);
   //     border: 1px solid red;

    }

    .price-type-block {
        padding-top: calc(var(--global-margin) * 1);
        padding-bottom: calc(var(--global-margin) * 0.8);

        .type-summary {
            .type-summary-expand-text {
                font-weight: bold;
                cursor: pointer;
                text-decoration: underline;
            }

            .price-col {
                font-size: calc(var(--global-font-size) * 1.2);
                font-weight: 600;
                color: var(--global-primary-background);
            }

            .desc-col {
                .desc-icon {
                    transition: transform 200ms ease-out;
                }

                &:hover {
                    .desc-icon {
                        transform: rotate(45deg);
                    }

                }

                &.active {
                    .desc-icon {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .type-details {

            .price-col {
                color: var(--global-primary-background);
            }
        }
    }
</style>
